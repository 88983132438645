import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Assuming you are using react-router for navigation
import { GetDetails } from '../Common/ServerApi';
import { Col, Container, Row } from 'react-bootstrap';

interface UserDetails {
    pet_name: string;
    type: string;
    gender: string;
    breed: string;
    age: number;
    first_name: string;
    last_name: string;
    phone: string;
    address: string;
    pincode: string;
    area: string;
    city: string;
    state: string;
    country: string;
}

const ScannedDetails: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [qrData, setQrData] = useState<UserDetails | null>(null);
    console.log('qrData', qrData)

    useEffect(() => {
        (async () => {
            const response = await GetDetails(token)
            setQrData(response?.data)
        })()
    }, [])



    return (
        <>
            <Container>
                <Row>
                    <Col lg={4} className='p-0 mx-auto'>
                        <div className='bg_color position-relative'>
                            <div className='position-relative'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="390" height="119" viewBox="0 0 390 119" fill="none" className='z-1'>
                                    <path d="M91.9756 118.946C54.6807 117.406 45.3404 74.8318 8.04627 73.2746C4.51657 73.1272 -1 73.2746 -1 73.2746V0H390C390 0 364.049 43.327 337.733 59.7238C289.777 89.6039 247.09 43.3626 192.992 59.7238C149.211 72.9649 137.682 120.832 91.9756 118.946Z" fill="url(#paint0_linear_207_798)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_207_798" x1="-1" y1="0" x2="65.2941" y2="217.824" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#EECE13" />
                                            <stop offset="1" stopColor="#B210FF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className='heading_details position-absolute top-10 z-2 text-white'>
                                    User Details
                                </div>
                            </div>
                            <div className='radius bg-white pt-2 p-0'>
                                <h4 className='ps-4'>Pet details</h4>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{qrData?.pet_name ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{qrData?.type ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>{qrData?.gender ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Breed</td>
                                            <td>{qrData?.breed ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Age</td>
                                            <td>{qrData?.age ?? '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h4 className='ps-4 mt-4'>Owner details</h4>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{qrData?.first_name ?? '-'} {qrData?.last_name ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{qrData?.phone ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{qrData?.address ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Pin Code</td>
                                            <td>{qrData?.pincode ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Area</td>
                                            <td>{qrData?.area ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>{qrData?.city ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>{qrData?.state ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Country</td>
                                            <td>{qrData?.country ?? '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ScannedDetails;
