import React, { useState } from "react";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import otpimg from '../assets/img/otp.png';
import OTPInput from "react-otp-input";
import { VerifyOtp1 } from "../Common/ServerApi";

interface VerifyOtpProps {
    add: boolean;
    handleClose: () => void;
    id: string;
}

const VerifyOtp: React.FC<VerifyOtpProps> = ({ add, handleClose, id }) => {
    const navigate = useNavigate();
    const [getotp, setGetOtp] = useState('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);


    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (!getotp || getotp.length < 6) {
            setLoading(false);
            setError('Invalid OTP. Please enter a valid 6-digit OTP.');
            return;
        }
        const otpString = getotp;

        const otp = parseInt(otpString, 10);
        try {
            setLoading(true);

            const response = await VerifyOtp1(otp, id);

            if (response?.status === true) {
                if (response?.data && typeof response?.data === 'object') {
                    navigate('/edit', { state: { data: response?.data } });
                } else {
                    setError('');
                    setLoading(false);
                    handleClose();
                    navigate('/generateqrcode', { state: { imageData: response?.data } });
                }
            } else {
                setError('Verification failed. Please try again.');
                setLoading(false);

            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setLoading(false);
        }
    };

    return (
        <Modal show={add} centered>
            <Modal.Body className="text-center p-5">
                <img src={otpimg} alt="OTP" />
                <h6 className="gradient_color text-center">Verification Code</h6>
                <p className="text-center resend_otp">We have sent you a code on the registered mobile number.</p>
                <Form onSubmit={handleSave} className="otp_form">
                    <OTPInput
                        value={getotp}
                        onChange={setGetOtp}
                        numInputs={6}
                        renderInput={(props: any) => <input {...props} className="otp_input justify-content-center" />}
                    />
                    {error && <p className="error-message text-danger">{error}</p>}

                    <p className="resend_otp">Didn’t receive the code? <span>RESEND OTP</span></p>
                    <div className="text-center mt-3">
                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    Verify OTP
                                </Tooltip>
                            )}
                            placement="top"
                        >
                            <Button className="btn-current" variant='success' type="submit" disabled={loading}>
                                {loading ? 'Loading...' : 'Verify'}
                            </Button>
                        </OverlayTrigger>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default VerifyOtp;
