import React, { useState } from 'react';
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const GenerateQRCode: React.FC = () => {
    const location = useLocation();
    const image = location.state?.imageData;
    const [loading, setLoading] = useState(false);

    const handleDownloadQRCode = () => {
        setLoading(true);
        if (image) {
            setLoading(false);

            const link = document.createElement('a');
            link.href = image;
            link.download = 'qrcode.png';
            link.click();
        } else {
            setLoading(false);

            console.log('No QR code image to download.');
        }
    };

    return (
        <Container>
            <Row>
                <Col lg={4} className='p-0 mx-auto'>
                    <div className='bg_color position-relative'>
                        <div className='position-relative'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="390" height="119" viewBox="0 0 390 119" fill="none" className='z-1'>
                                <path d="M91.9756 118.946C54.6807 117.406 45.3404 74.8318 8.04627 73.2746C4.51657 73.1272 -1 73.2746 -1 73.2746V0H390C390 0 364.049 43.327 337.733 59.7238C289.777 89.6039 247.09 43.3626 192.992 59.7238C149.211 72.9649 137.682 120.832 91.9756 118.946Z" fill="url(#paint0_linear_207_798)" />
                                <defs>
                                    <linearGradient id="paint0_linear_207_798" x1="-1" y1="0" x2="65.2941" y2="217.824" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#EECE13" />
                                        <stop offset="1" stopColor="#B210FF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className='heading_details position-absolute top-10 z-2 text-white'>
                                <Link to="/">  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" className='me-2'>
                                    <rect x="36" y="35" width="35" height="35" rx="17.5" transform="rotate(179.19 36 35)" fill="url(#paint0_linear_101_1899)" />
                                    <path d="M26.1046 17.4449C26.0973 16.987 25.7201 16.6205 25.2622 16.6262L14.6831 16.7586L19.1107 12.7302C19.5006 12.3756 19.4907 11.7586 19.0897 11.4138C18.7525 11.1239 18.253 11.1302 17.9253 11.4284L11.4953 17.2784C11.2885 17.4666 11.2938 17.7939 11.5065 17.9768L18.121 23.6637C18.4581 23.9536 18.9577 23.9473 19.2854 23.6491C19.6752 23.2944 19.6654 22.6775 19.2643 22.3327L14.7096 18.4167L25.2887 18.2844C25.7466 18.2786 26.1119 17.9028 26.1046 17.4449Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_101_1899" x1="36" y1="35" x2="71" y2="70" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F97794" />
                                            <stop offset="1" stopColor="#623AA2" />
                                        </linearGradient>
                                    </defs>
                                </svg> </Link>QRCode
                            </div>
                        </div>
                        <div className='generate radius bg-white d-flex flex-column justify-content-center align-items-center'>
                            {image ? (
                                <img src={image} alt="QR Code" width={300} />
                            ) : (
                                <p>No image data available</p>
                            )}

                            <div className='text-center'>
                                <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>
                                            Download QR code
                                        </Tooltip>
                                    )}
                                    placement="top"
                                >
                                    <Button variant='success'
                                        className="btn-current mt-4 me-2"
                                        onClick={handleDownloadQRCode}
                                        disabled={loading}
                                    >
                                        {loading ? 'Loading...' : 'Download'}
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className='bottom_svg_vector'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="274" viewBox="0 0 100 274" fill="none">
                            <path d="M-268.968 170.541C-269.874 204.334 -251.675 251.123 -214.212 242.675C-185.895 236.29 -211.661 204.154 -176.84 185.981C-142.019 167.807 -96.5901 173.269 -74.8614 203.192C-58.8608 225.226 -87.2195 246.254 -74.8614 270.01C-46.4556 324.615 94.4445 312.594 99.8342 252.799C103.407 213.164 48.2306 207.401 31.1728 170.541C10.4472 125.757 52.936 85.6182 19.2946 47.2812C-6.14661 18.2889 -35.2008 -8.84112 -74.8614 2.73538C-126.369 17.77 -25.6103 104.234 -74.8614 124.224C-97.7427 133.511 -114.512 127.469 -139.467 124.224C-169.972 120.257 -184.289 95.4283 -214.212 101.951C-250.481 109.857 -268.092 137.893 -268.968 170.541Z" fill="url(#paint0_linear_207_795)" fillOpacity="0.59" />
                            <defs>
                                <linearGradient id="paint0_linear_207_795" x1="-269" y1="0" x2="30.5491" y2="362.405" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF6FD8" />
                                    <stop offset="1" stopColor="#3813C2" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default GenerateQRCode;
