import axios, { AxiosRequestConfig } from "axios";

// API configurations
export const ServerAPI = {
  APIMethod: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  } as const,
};

export const API = {
  BaseUrl: "/api/",
};

export const EndPoint = {
  sendotp: "send-otp",
  verifyotp: "verify-otp",
  getdetails: "get-user-details",
  updatedetails: "update-user-details",
  getstateinfobypin: "get-state-info-by-pin",
  getaddressinfo: "get-address-info",
};


// Axios instance with HTTPS agent
const apiInstance = axios.create({
  baseURL: API.BaseUrl,
  withCredentials: true,
});
// Request Interceptor
apiInstance.interceptors.request.use(
  (config) => {
    // Add any headers you need, such as Authorization
    // config.headers = {
    //   ...config.headers,
    //   Authorization: `Bearer ${localStorage.getItem("token")}`, // Example: token stored in localStorage
    // };
    console.log("Request:", config);
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Response Interceptor
apiInstance.interceptors.response.use(
  (response) => {
    console.log("Response:", response);
    return response;
  },
  (error) => {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      if (error.response) {
        if (error.response.status === 401) {
          console.error("Unauthorized: Redirecting to login.");
          // Optional: Redirect to login if unauthorized
        } else if (error.response.status >= 500) {
          console.error("Server Error: Please try again later.");
        }
      } else if (error.code === "ERR_NETWORK") {
        console.error("Network Error: Please check your connection.");
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return Promise.reject(error);
  }
);




// Updated executeAPI function with Axios instance
export const executeAPI = async (
  method: keyof typeof ServerAPI.APIMethod,
  data: any,
  endpoint: string,
  params?: any
): Promise<any> => {
  try {
    const config: AxiosRequestConfig = {
      method,
      url: endpoint,
      data,
      params,
      headers: {
        'User-Agent': 'YourApp/1.0',
        'Content-Type': 'application/json'
      },
    };
    const response = await apiInstance(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};




export interface SendOtp {
  first_name: string,
  last_name: string,
  phone: number,
  pet_name: string,
  type: string,
  gender: string,
  breed: string,
  dob: string,
  age: number,
  address: string,
  area: string,
  pincode: number,
  city: string,
  country: string,
  state: string,
}

export interface UpdateDetails {

  first_name: string,
  last_name: string,
  phone: number,
  pet_name: string,
  type: string,
  gender: string,
  breed: string,
  dob: string,
  age: number,
  address: string,
  area: string,
  pincode: number,
  city: string,
  country: string,
  state: string,
}

export const SendOtp = async (first_name: string, last_name: string, phone: number, pet_name: string, type: string, gender: string, breed: string, dob: string, age: number, address: string, area: string, pincode: number, city: string, country: string, state: string) => {
  const data: SendOtp = { first_name, last_name, phone, pet_name, type, gender, breed, dob, age, address, area, pincode, city, country, state };
  return await executeAPI(ServerAPI.APIMethod.POST, data, EndPoint.sendotp);
};

export const UpdateDetails = async (first_name: string, last_name: string, phone: number, pet_name: string, type: string, gender: string, breed: string, dob: string, age: number, address: string, area: string, pincode: number, city: string, country: string, state: string, id: string) => {
  const data: UpdateDetails = { first_name, last_name, phone, pet_name, type, gender, breed, dob, age, address, area, pincode, city, country, state };
  return await executeAPI(ServerAPI.APIMethod.POST, data, `${EndPoint.updatedetails}/${id}`);
};


export interface VeriftOtp {
  otp: number,
  id: string
}


export const VerifyOtp1 = async (otp: number, id: string) => {
  const data: VeriftOtp = { otp, id };
  return await executeAPI(ServerAPI.APIMethod.POST, data, EndPoint.verifyotp);
};


export const GetDetails = async (token: any) => {
  return await executeAPI(ServerAPI.APIMethod.GET, null, `${EndPoint.getdetails}/${token}`);
};

export const GetStateUsingPincode = async (pincode: any) => {
  return await executeAPI(ServerAPI.APIMethod.GET, null, `${EndPoint.getstateinfobypin}?pincode=${pincode}`);
};

export const GetLocation = async (latitude: any, longitude: any) => {
  return await executeAPI(ServerAPI?.APIMethod?.GET, null, `${EndPoint?.getaddressinfo}?latitude=${latitude}&longitude=${longitude}`);
};
